.headerWrap {
  .mobileNav {
    @media screen and (min-width: 768px) {
      display: none;
    }
    .navContain {
      position: relative;
      z-index: 7;
      background: white;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      .top {
        padding: 18px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 82px;
            height: 32px;
          }
        }
      }
      .bottom {
        position: relative;
        height: 100%;
        width: 100%;
        ul {
          position: absolute;
          z-index: 4;
          list-style-type: none;
          margin-left: 0;
          width: 100%;
          background: white;
          left: 0;
          a {
            padding: 18px;
            border-bottom: 1px solid rgba(115, 110, 110, 0.1);
            text-decoration: none;
            display: block;
            font-size: 17px;
            color: rgba(115, 110, 110, 1);
            background: white;
            &.active {
              background-color: rgba(254, 0, 2, 0.1);
            }
          }
        }
      }
    }
    .cover {
      position: fixed;
      background: rgba(1, 1, 1, 0.5);
      z-index: 2;
      height: 100vh;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .desktopNav {
    @media screen and (max-width: 768px) {
      display: none;
    }
    padding: 44px 48px 44px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      img {
        width: 124px;
        height: 48px;
      }
    }
    .navLinks {
      display: flex;
      align-items: center;
      a {
        display: block;
        margin-right: 48px;
        text-decoration: none;
        color: #736e6e;
        font-weight: 500;
        position: relative;
        span {
          font-size: 18px;
        }
        .active {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 10%;
        }
        &.hire {
          background: linear-gradient(90deg, #fe0002 -116.52%, #ffaa77 159.77%);
          padding: 14px 64px;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          margin-right: 0;
        }
      }
    }
  }
}
